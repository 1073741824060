.view{
    background-color: #222;
    height: 30vh;
    overflow: hidden;
    position: relative;
   
}.view img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.01);
}.view .date{
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 12px;
    background-color: #33333340;
    padding: 2px;
    color: #fff;
}


.video{
    background-color: #e5d6ed;
    /* height: 30vh; */
    overflow: hidden;
    position: relative;
}.video .main{
    background-color: #000;
    height: 260px;
    overflow: hidden;
}.video video{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    transform: scale(1.3);
}.video video:not(.active){
    z-index: 100;
}.video video.active{
    /* display: none; */
    z-index: 1000;
}


[name="videolist"]{
    padding: 3px 0px;
    border: 0px;
    background-color: transparent;
    outline: 0;
    color: #4b2560;
}