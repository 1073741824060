.questioninline{
	display: inline-table;
}

[question]{
	display: block;
	width: 40px;
    text-align: center;
}

[alternatives]:not(.active){
	display: none;
}

[response]{
	display: inline-table;
	width: 40px;
    text-align: center;
}