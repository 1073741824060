/* .view{
    background-color: #222;
    height: 30vh;
    overflow: hidden;
    position: relative;
   
}.view img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.01);
}.view .date{
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 12px;
    background-color: #33333340;
    padding: 2px;
    color: #fff;
}
 */
 .SettingsMap.view-images .listing{
    max-height: 106px !important;
    background-color: #333;
}.SettingsMap.view-images .listing li{
    float: left;
    width: 50%;
    /* height: 130px; */
    position: relative;
}.SettingsMap.view-images .listing li label{
    background-color: #222222a6;
    display: block;
    margin: 0px;
    color: #ccc;
    position: absolute;
    padding: 5px;
    left: 0px;
    top: 5px;
}.SettingsMap.view-images .listing li img{
    width: 100%;
    height: 106px;
    display: block;
    object-fit: cover;
    transition: 400ms;
}.SettingsMap.view-images .listing li label{
}.SettingsMap.view-images .listing li:hover{
    /* transform: scale(1.1); */
    filter: brightness(1.2);
    cursor: pointer;
}

.SettingsMap.view-images .listing::-webkit-scrollbar {
    width: 3px !important;
}.SettingsMap.view-images .listing::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 5px #222;
}.SettingsMap.view-images .listing::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 0 5px #ce8ef7;
}

.SettingsMap.view-images .imagesingle{
    width: 100%;
    height: 100%;
    margin: 0px;
    display: block;
    padding: 0px;
    object-fit: cover;
}