.--splash{
}

.layout_splash{
	/*background: linear-gradient(180deg, #350452, #c66bff);*/
	background: linear-gradient(180deg, #350452, #9227d5);
}
.layout_splash img.logo{
	/*filter: brightness(0.6) hue-rotate(354deg);*/
	filter: brightness(2) hue-rotate(-24deg);
}
.layout_splash h1{
	/*color: #460072;*/

	color: #cf83ff;
    padding: 0px;
    font-size: 23px;
    font-weight: normal;
}
